@use "../theme";

@use "@material/button";
// with (
//   $outline-color: theme.$primary,
// );

@import "../theme";
@import "@material/button/mdc-button.scss";
// @import '../typography/typography.scss';

.mdc-button {
  --mdc-typography-button-text-transform: none;
  --mdc-typography-button-font-size: 1em;

  &.mdc-theme--secondary-bg {
    @include button.shape-radius(9999px);
  }

  &.selection-button {
    width: 100%;
    color: #000;
    border: 1px solid #000000;
    @include button.shape-radius(0px);

    &[data-selected="true"] {
      background-color: #ebfdff;
      border: 2px solid #377a80;
      color: #000;
    }
  }

  &.ghost:not(:disabled) {
    border: 2px solid #377a80;

    &[data-state="danger"] {
      border: 2px solid #db1b1b;
      color: #db1b1b;
      width: 100%;
    }
  }

  // &.mdc-button--unelevated:not([disabled]) {
  //   background-color: #377a80;
  // }

  // &.mdc-button--outlined:not([disabled]) {
  // @include button.shape-radius(9999px);
  // background-color: #ebb114;
  // border-color: #ebb114;
  // }

  height: auto;

  .mdc-button__label {
    padding-top: 12px;
    padding-bottom: 12px;
    line-height: 1.5em;
  }

  &[data-size="small"] {
    .mdc-button__label {
      padding-top: 4px;
      padding-bottom: 4px;
    }
  }

  &[data-size="large"] {
    font-size: 1.25em;

    .mdc-button__label {
      padding-top: 17px;
      padding-bottom: 17px;
    }
  }
}
