.alert {
  display: flex;
  line-height: 1em;
  padding: 12px 16px;
  align-items: center;
  >div:first-child {
    flex: 1 1 auto;
  }

  >button:last-child {
    // flex: 1 1 auto;
    text-transform: uppercase;
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding: 0;
    margin: 0;
    font-weight: bold;
    color: inherit;
  }

}