@font-face {
  font-family: Apercu-Black-Pro;
  src: url('~@asurion/asurion-design-assets-asurion/fonts/Apercu-Black-Pro.ttf');
}

@font-face {
  font-family: Apercu-Bold-Pro;
  src: url('~@asurion/asurion-design-assets-asurion/fonts/Apercu-Bold-Pro.ttf');
}

@font-face {
  font-family: Apercu-Light-Pro;
  src: url('~@asurion/asurion-design-assets-asurion/fonts/Apercu-Light-Pro.ttf');
}

@font-face {
  font-family: Apercu-Regular-Pro;
  src: url('~@asurion/asurion-design-assets-asurion/fonts/Apercu-Regular-Pro.ttf');
}

@font-face {
  font-family: ApercuMono-Light-Pro;
  src: url('~@asurion/asurion-design-assets-asurion/fonts/ApercuMono-Light-Pro.ttf');
}

@font-face {
  font-family: ApercuMono-Regular-Pro;
  src: url('~@asurion/asurion-design-assets-asurion/fonts/ApercuMono-Regular-Pro.ttf');
}

* {
  font-family: "Apercu-Regular-Pro", sans-serif;
}

body {
  --mdc-typography-body2-font-family: "Apercu-Regular-Pro"
}