@import "~@asurion/asurion-design-assets-asurion/asurion.css";
// @import "@material/linear-progress/mdc-linear-progress.scss";

@import "./modules/amwc/theme";

* {
  position: relative;
}

html,
body {
  height: 100%;
}

strong {
  font-family: "Apercu-Bold-Pro";
}

html,
body,
#root {
  padding: 0;
  margin: 0;
  color: #333333;
  overflow: hidden;
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
}

a {
  color: $primary;
}
