$primary: #377A80 !default;
$secondary: #EBB114 !default;
$error: #d45c51 !default;
$on-secondary: #000000 !default;

@use "@material/theme" with (
  $primary: $primary,
  $secondary: $secondary,
  $background: #fff,
  $error: $error,
  $on-secondary: $on-secondary,
  );

@import '@material/theme/mdc-theme.scss';

